<template>
  <div id="auth-layout">
    <router-view/>
  </div>
</template>

<script>
  export default {
    name: "AuthLayout"
  }
</script>

<style>

  div.my-login-page {
    background-color: #f7f9fb;
    font-size: 15px;
    min-height: 100vh;
    width: 100%;
    /*background-image: url(../assets/backgroundimg.png);
    background-size: 100%;
    background-position: bottom;
    background-repeat: no-repeat;*/
    background: linear-gradient(180deg, #4D8CEE 89%, #4D8CEE 12%, #4D8CEE 0%);

  }

  .my-login-page .brand {
    width: 350px;
    overflow: hidden;
    /*border-radius: 50%;*/
    margin: 15px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, .05);
    position: relative;
    z-index: 1;
  }

  .my-login-page .brand img {
    width: 100%;
  }

  .my-login-page .brand-name {
    width: 50%;
    display: flex;
    align-items: center;
    JUSTIFY-CONTENT: flex-end;
    padding: 10px;
    border-radius: 0 50px 50px 0;
    background: rgb(255 255 255 / 0%);
    box-shadow: 0px 0px 50px 0px rgba(252, 252, 252, 1);
  }

  .my-login-page .brand-name h6 {
    font-size: 28px;
    font-weight: 800;
    line-height: 22.47px;
    letter-spacing: 0.3em;
    text-align: left;
    margin: 0;
    color: #fff;
    text-shadow: 0px 0px 20px rgba(255, 255, 255, 1);
  }

  .my-login-page .card-wrapper {
    width: 500px;
  }

  .my-login-page .card {
    border-color: transparent;
    box-shadow: 0 4px 8px rgba(0, 0, 0, .05);
  }

  .my-login-page .card.fat {
    padding: 25px;
    background-color: rgba(255, 255, 255, 0.01);
    box-shadow: 0 0 20px 3px #ffffff66;
    border-radius: 20px;
  }

  .my-login-page .card .card-title {
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.5);
  }

  .my-login-page .form-link {
    color: rgb(255 255 255 / 50%);
  }

  .my-login-page a {
    color: #fff;
    text-decoration: none;
    font-size: 15px;
    letter-spacing: 1px;
  }

  .my-login-page a:hover {
    text-decoration: underline;
  }

  .custom-mt-1 {
    margin-top: 1rem;
  }

  .custom-mt-2-5 {
    margin-top: 2.5rem;
  }

  .my-login-page .form-group {
    position: relative;
  }

  .my-login-page .form-control {
    border: none;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.27);
    padding: 4px 10px;
    font-size: 14px;
    transition: 0.25s ease;
  }
  
  .my-login-page .form-control::placeholder { /* Most modern browsers support this now. */
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
  }

  .my-login-page .form-control:focus,
  .p-button:focus {
    outline: 0;
    box-shadow: 0 0 15px -7px rgb(0 0 6);
  }

  .my-login-page .form-group label {
    width: 100%;
    color: rgba(255, 255, 255, 0.7);
    padding: 0px 10px;
    position: absolute;
    top: 4px;
    transition: 0.25s ease;
  }

  .my-login-page .form-control:focus + label, .my-login-page .form-control:valid + label {
    transform: translateY(-1.7rem);
  }

  .my-login-page button {
    background: rgba(255, 255, 255, 0.27);
    border: none;
    border-radius: 10px;
    padding: 4px 14px 7px;
    font-size: 15px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.5);
  }

  .my-login-page .warning-text {
    font-size: 11px;
    line-height: 13px;
    margin-bottom: 0px;
    color: #fff;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .p-button:enabled:hover,
  .my-login-page button:hover {
    background: rgb(255 255 255 / 44%);
  }

  .p-button:active {
    transform: scale(0.98);
  }


  .my-login-page .btn.btn-block {
    padding: 12px 10px;
  }

  .my-login-page .footer {
    margin: 40px 0;
    color: #888;
    text-align: center;
  }

  .auth-btn {
    width: 100%;
  }

  .reg-btn {
    width: 100%;
  }

  @media screen and (max-width: 1040px) {
    .my-login-page-inner {
      min-height: 100vh;
      max-width: 80%;
      padding: 30px 0px;
      flex-direction: column;
      margin: 0 auto;
      justify-content: center !important;
      gap: 30px;
    }
    .my-login-page .brand-name {
      border-radius: 50px;
      justify-content: center;
      width: calc(400px - 25px);
      margin: 0 auto;
      margin-right: calc(var(--bs-gutter-x) / -2);
      margin-left: calc(var(--bs-gutter-x) / -2);
    }
    .my-login-page .brand-name h6 {
      text-align: center;
      font-size: 23px;
    }
    .my-login-page .card-wrapper {
      width: 100%;
    }
  }

  @media screen and (max-width: 425px) {
    .my-login-page .card-wrapper {
      width: 90%;
      margin: 0 auto;
    }
    .my-login-page .brand-name {
      width: calc(320px - 16px);
    }
  }

  @media screen and (max-width: 320px) {
    .my-login-page .card.fat {
      padding: 0;
    }

    .my-login-page .card-wrapper {
      width: 290px;
    }

    .my-login-page .brand-name {
      width: calc(290px - 25px);
    }

    .my-login-page .card.fat .card-body {
      padding: 15px;
    }
  }
</style>
